import { Grid } from '@mui/material';
import React from 'react';
import '../AboutMe/Skill.css';
import Box from '@mui/material/Box';



const SingleProduct = () => {




    return (
        <div>


            <Grid container spacing={9}>



                <Grid item sx={12} md={4} lg={3}>


                <Box
        sx={{
          boxShadow: 0,
          width: '8rem',
          height: '5rem',
          bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
          color: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
          p: 1,
          m: 1,
          borderRadius: 2,
          textAlign: 'center',
          fontSize: '0.875rem',
          fontWeight: '700',
          width:"500",
          height:"200"
        }}
      >



        <img  
                            src="https://img.freepik.com/free-vector/realistic-glassmorphism-elements-collection_52683-86870.jpg?w=740&t=st=1698336356~exp=1698336956~hmac=94c00296016502b97301e1a3b4932485ea82efb2f1617b8a36734b98e5ca8544" alt=""  width={300} height={230}/>
      </Box>


      <h3  style={{
             color:'white',
             fontSize:'20px'
             }}>Windows Apps</h3>




      </Grid>









      <Grid item sx={12} md={4} lg={3}>


                    <Box
                            sx={{
                            boxShadow: 0,
                            width: '8rem',
                            height: '5rem',
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) =>
                            theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                            p: 1,
                            m: 1,
                            borderRadius: 2,
                            textAlign: 'center',
                            fontSize: '0.875rem',
                            fontWeight: '700',
                            width:"500",
                            height:"200"
                            }}
                            >



                            <img  
                                        src="https://img.freepik.com/free-vector/modern-android-icon_1035-9121.jpg?w=740&t=st=1698336226~exp=1698336826~hmac=2d7a68cc89c5fe31ad2d7d424dc9cde7087e8bf58111b3198b29b0e32bb2bd5d" alt=""  width={300} height={230}/>
                            </Box>


                            <h3  style={{
                            color:'white',
                            fontSize:'20px'
                            }}>Android App</h3>




                            </Grid>






                            <Grid item sx={12} md={4} lg={3}>


                                        <Box
                                                sx={{
                                                boxShadow: 0,
                                                width: '8rem',
                                                height: '5rem',
                                                bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                                                color: (theme) =>
                                                theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                                                p: 1,
                                                m: 1,
                                                borderRadius: 2,
                                                textAlign: 'center',
                                                fontSize: '0.875rem',
                                                fontWeight: '700',
                                                width:"500",
                                                height:"200"
                                                }}
                                                >



                                                <img  
                                                            src="https://img.freepik.com/free-vector/gradient-ui-ux-background_23-2149065783.jpg?w=1060&t=st=1698336461~exp=1698337061~hmac=d352b8e3d494b9ec621d66a213b4438b4d668468baac427a6fdf2b878f75071a" alt=""  width={300} height={230}/>
                                                </Box>


                                                <h3  style={{
                                                color:'white',
                                                fontSize:'20px'
                                                }}>IOS App</h3>




                                                </Grid>





     <Grid item sx={12} md={4} lg={3}>


<Box
        sx={{
        boxShadow: 0,
        width: '8rem',
        height: '5rem',
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
        color: (theme) =>
        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
        p: 1,
        m: 1,
        borderRadius: 2,
        textAlign: 'center',
        fontSize: '0.875rem',
        fontWeight: '700',
        width:"500",
        height:"200"
        }}
        >



        <img  
                    src="https://img.freepik.com/free-vector/template-technology-concept-landing-page_23-2148336174.jpg?w=1060&t=st=1698336539~exp=1698337139~hmac=d61b83e84cb85746fae7a9a1f6fac07807e4768d9c7c1e4136815e69355e76de" alt=""  width={300} height={230}/>
        </Box>


        <h3  style={{
        color:'white',
        fontSize:'20px'
        }}>Web App</h3>




        </Grid>









        <Grid item sx={12} md={4} lg={3}>


<Box
        sx={{
        boxShadow: 0,
        width: '8rem',
        height: '5rem',
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
        color: (theme) =>
        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
        p: 1,
        m: 1,
        borderRadius: 2,
        textAlign: 'center',
        fontSize: '0.875rem',
        fontWeight: '700',
        width:"500",
        height:"200"
        }}
        >



        <img  
                    src="https://img.freepik.com/free-vector/gradient-website-hosting-illustration_23-2149247164.jpg?w=740&t=st=1698336742~exp=1698337342~hmac=e0539d222284087af91162de20ea8c133cd1fe31ccb5122213622798cc22e5c0" alt=""  width={300} height={230}/>
        </Box>


        <h3  style={{
        color:'white',
        fontSize:'20px'
        }}>Back End Development</h3>




        </Grid>












        <Grid item sx={12} md={4} lg={3}>


<Box
        sx={{
        boxShadow: 0,
        width: '8rem',
        height: '5rem',
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
        color: (theme) =>
        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
        p: 1,
        m: 1,
        borderRadius: 2,
        textAlign: 'center',
        fontSize: '0.875rem',
        fontWeight: '700',
        width:"500",
        height:"200"
        }}
        >



        <img  
                    src="https://img.freepik.com/free-photo/use-apps-make-friends-concept-with-smartphone_23-2150296218.jpg?w=900&t=st=1698336854~exp=1698337454~hmac=76826d397927731651405d960d66678d1e990fb016a3ced56d7a176604eade7a" alt=""  width={300} height={230}/>
        </Box>


        <h3  style={{
        color:'white',
        fontSize:'20px'
        }}>SMS Integration Apps </h3>




        </Grid>








        

        <Grid item sx={12} md={4} lg={3}>


<Box
        sx={{
        boxShadow: 0,
        width: '8rem',
        height: '5rem',
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
        color: (theme) =>
        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
        p: 1,
        m: 1,
        borderRadius: 2,
        textAlign: 'center',
        fontSize: '0.875rem',
        fontWeight: '700',
        width:"500",
        height:"200"
        }}
        >



        <img  
                    src="https://img.freepik.com/free-vector/set-taxi-app-interface-smartphone_23-2148476378.jpg?w=740&t=st=1698337170~exp=1698337770~hmac=b0fcd3b0a25c11395e15179abb05820801509d30c14c27e61a27594a3a30bc54" alt=""  width={300} height={230}/>
        </Box>


        <h3  style={{
        color:'white',
        fontSize:'20px'
        }}>Google Map Integration Apps</h3>




        </Grid>







        <Grid item sx={12} md={4} lg={3}>


<Box
        sx={{
        boxShadow: 0,
        width: '8rem',
        height: '5rem',
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
        color: (theme) =>
        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
        p: 1,
        m: 1,
        borderRadius: 2,
        textAlign: 'center',
        fontSize: '0.875rem',
        fontWeight: '700',
        width:"500",
        height:"200"
        }}
        >



        <img  
                    src="https://img.freepik.com/free-photo/ai-site-helping-with-software-production_1268-21620.jpg?w=1380&t=st=1698337324~exp=1698337924~hmac=63082c598c3c29055d29f021813020620a64dd98b99d3a842eefebeadc4123b2" alt=""  width={300} height={230}/>
        </Box>


        <h3  style={{
        color:'white',
        fontSize:'20px'
        }}>Linus Apps</h3>




        </Grid>




















                


               





            </Grid>
















            
        </div>
    );
};

export default SingleProduct;