import { Grid } from '@mui/material';
import React from 'react';
import './Home.css';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import { useNavigate } from "react-router-dom";
import { height } from '@mui/system';
import AboutMe from '../AboutMe/AboutMe';
import Contact from '../Contact/Contact';
import Project from '../Projects/Project';
import Celebrate from '../Celebration/Celebrate';
import OurProducts from '../OurProducts/OurProducts';




const Home = () => {

      // Route Change Function
//   let navigate = useNavigate();
//   const routeChange = (path) =>{ 
    
 
//       navigate(path)
//   }



const [index, setIndex] = React.useState(0);







    return (
        <div>

            <div className="home-box" >


                <div className="home-container">

                    <div className="home-navigation-box" id='home'>

                        <div 
                        style={{
                            backgroundColor:'#0B0952'
                        }}
                        className="home-navigation-container">

                    <Grid container spacing={9}>
                      <Grid item xs={12} md={6} lg={6}>
                     
                       <Grid style={{
                           color:'white',
                           fontSize:'30px'
                       }} item xs={12} md={12} lg={12}>




                     












                   
                      </Grid>


                      <Grid  item xs={12} md={12} lg={12}>
                      <h2 
                      style={{
                        color:'white',
                        fontSize:'40px'
                    }}
                      > Welcome From <span className='first-name'>InanSoft</span> </h2>

                       <p 
                         style={{
                            color:'white',
                            fontSize:'17px'
                        }}className='my-home-desc'>We have been working on various software products for the past 5 years.
                        You will find various software at low prices here.</p>
                                          
                      </Grid>


                      <Grid item sx={12} md={12} lg={12}>

                      <div className='icons'>


                      <div className="git-icon"
                    >
                         <a href="https://github.com/Developer20202021/">
                            
                         <GitHubIcon/>
                         
                         </a>
                              
                          </div>
                          <div className="linked-icon"
                
                          >
                              <a href="https://linkedin.com/in/mahadi-onlyme">
                              <LinkedInIcon/>
                              </a>
                        
                          </div>






                      </div>




                      </Grid>

                   
                         </Grid>



                     
                     <Grid item xs={12} md={6} lg={6}>



                     <Grid  item xs={12} md={12} lg={12}>

                       <div className='my-image'>

                       <img className='my-photo'
                        
                           src="https://img.freepik.com/free-vector/laptop-with-program-code-isometric-icon-software-development-programming-applications-dark-neon_39422-971.jpg?w=900&t=st=1698338247~exp=1698338847~hmac=7a07686086c3f3680e3cd168e94211cb1c713661cb74d49f3f337fb0497e1efb" alt="" height={200}/>
                       </div>
                       


                      </Grid>


                      </Grid>

                    </Grid>



                        </div>
                        
                        <div className="about-home"  style={{
            backgroundColor:'#070640'
        }} id='about'>

                    <AboutMe></AboutMe>


                </div>



                             
    <div className="about-home"  style={{
            backgroundColor:'#070640'
        }} id='about'>

                    <OurProducts></OurProducts>


                </div>

             



                <div className="project-home" style={{
            backgroundColor:'#070640'
        }} id='projects'>
                
                <h2 className='works' style={{
                        color:'white',
                        fontSize:'70px'
                    }}>Our Works</h2>

                    <Project></Project>

                    





                </div>

                <div>
                    <Celebrate></Celebrate>
                </div>

            





                

                <div className="contact-form" style={{
            backgroundColor:'#070640'
        }} id='contact'>
                
                <h2 className='lets' style={{
                        color:'white',
                        fontSize:'70px'
                    }}>Let's Talk</h2>

                    <Contact></Contact>





                </div>



                    </div>

                    





                </div>




                           {/* about  */}


             










            </div>

            
        </div>
    );
};

export default Home;