import { Grid } from '@mui/material';
import React, { useState } from 'react';
import '../AboutMe/AboutMe.css'
import SingleProduct from './OurSingleProduct';

const OurProducts = () => {


    const [num, setNum] = useState(1);


    const about = (num) =>{

        setNum(num)



    }

    // const skill = ()=>{

    //     setNum(2)
    // }

    // const services = ()=>{

    //     setNum(3)
    // }




    return (
        <div>

            <div className="about-container">


               <div className="title-box">
                   <h2  style={{
                        color:'white',
                        fontSize:'70px'
                    }}>Our Products</h2>
               </div>


               <div className="about-header">
                   <div className={num===1?'active':"list"} onClick={()=>about(1)}>About</div>
                   <div className={num===2?'active':"list"} 
                   onClick={()=>about(2)}
                   >Products</div>
                 
                   
               </div>


               <div className="about-all-box">

                {num === 1?   <Grid container spacing={9}>


<Grid item xs={12} md={6} lg={6}>

    <div className="my-image">
        <img  className='my-photo'
                            src="https://img.freepik.com/premium-photo/web-development-programming-code-app-development-web-design-computer-seo-3d-background_125322-458.jpg?w=1060" alt=""  />
    </div>





</Grid>

<Grid item xs={12} md={6} lg={6}>

    <div className="about-image">
    <h2  style={{
     color:'white',
     fontSize:'50px'
 }}> Make Your Life Easier with Our Software</h2>
 
 <p style={{
     color:'white',
     fontSize:'20px'
 }}>We have been working on various software products for the past 5 years.
 We always work with update technology.We create Websites, Mobile Apps, Desktop Apps for various items including Ecommerce, Live tracking, Product Management, School Management, Food Delivery System, Stock Management, Courier Product Delivery Management.We don't just sell a product. Rather, we provide training for them.You will always get Live Support from us. We are always active.Business is yours but it is our responsibility to manage it with software and make it easily available.

 </p>


      
    </div>





</Grid>

</Grid>:null}



        {num===2?
        <SingleProduct></SingleProduct>:null
    
    }



               </div>








            </div>


            
        </div>
    );
};

export default OurProducts;