import { Grid } from '@mui/material';
import React from 'react';
import './Skill.css';



const Skill = () => {




    return (
        <div>


            <Grid container spacing={9}>



                <Grid item sx={12} md={4} lg={3}>


                <div class="set-size charts-container">
            <div class="pie-wrapper progress-90">
                <span class="label">90<span     class="smaller">%</span></span>
                 <div class="pie">
                  <div class="left-side half-circle"></div>
                    <div class="right-side half-circle"></div>
            </div>
         </div>
         </div>

         <h3  style={{
             color:'white',
             fontSize:'20px'
             }}>HTML</h3>

                </Grid>


                <Grid item sx={12} md={4} lg={3}>


                <div class="set-size charts-container">
            <div class="pie-wrapper progress-90">
                <span class="label">90<span     class="smaller">%</span></span>
                 <div class="pie">
                  <div class="left-side half-circle"></div>
                    <div class="right-side half-circle"></div>
            </div>
         </div>
         </div>

         <h3  style={{
             color:'white',
             fontSize:'20px'
             }}>CSS</h3>

                </Grid>


                <Grid item sx={12} md={4} lg={3}>


                <div class="set-size charts-container">
            <div class="pie-wrapper progress-75">
                <span class="label">75<span     class="smaller">%</span></span>
                 <div class="pie">
                  <div class="left-side half-circle"></div>
                    <div class="right-side half-circle"></div>
            </div>
         </div>
         </div>

         <h3  style={{
             color:'white',
             fontSize:'20px'
             }}>JavaScript</h3>

                </Grid>


                <Grid item sx={12} md={4} lg={3}>


                <div class="set-size charts-container">
            <div class="pie-wrapper progress-95">
                <span class="label">95<span     class="smaller">%</span></span>
                 <div class="pie">
                  <div class="left-side half-circle"></div>
                    <div class="right-side half-circle"></div>
            </div>
         </div>
         </div>

         <h3  style={{
             color:'white',
             fontSize:'20px'
             }}>React JS</h3>

                </Grid>

                <Grid item sx={12} md={4} lg={3}>


                <div class="set-size charts-container">
            <div class="pie-wrapper progress-75">
                <span class="label">75<span     class="smaller">%</span></span>
                 <div class="pie">
                  <div class="left-side half-circle"></div>
                    <div class="right-side half-circle"></div>
            </div>
         </div>
         </div>

         <h3  style={{
             color:'white',
             fontSize:'20px'
             }}>Dart</h3>

                </Grid>

                <Grid item sx={12} md={4} lg={3}>


                <div class="set-size charts-container">
            <div class="pie-wrapper progress-45">
                <span class="label">65<span     class="smaller">%</span></span>
                 <div class="pie">
                  <div class="left-side half-circle"></div>
                    <div class="right-side half-circle"></div>
            </div>
         </div>
         </div>

         <h3  style={{
             color:'white',
             fontSize:'20px'
             }}>Java</h3>

                </Grid>

                <Grid item sx={12} md={4} lg={3}>


                <div class="set-size charts-container">
            <div class="pie-wrapper progress-60">
                <span class="label">60<span     class="smaller">%</span></span>
                 <div class="pie">
                  <div class="left-side half-circle"></div>
                    <div class="right-side half-circle"></div>
            </div>
         </div>
         </div>

         <h3  style={{
             color:'white',
             fontSize:'20px'
             }}>React Native</h3>

                </Grid>

                <Grid item sx={12} md={4} lg={3}>


                <div class="set-size charts-container">
            <div class="pie-wrapper progress-90">
                <span class="label">40<span     class="smaller">%</span></span>
                 <div class="pie">
                  <div class="left-side half-circle"></div>
                    <div class="right-side half-circle"></div>
            </div>
         </div>
         </div>

         <h3  style={{
             color:'white',
             fontSize:'20px'
             }}>Python</h3>

                </Grid>



                
                <Grid item sx={12} md={4} lg={3}>


                <div class="set-size charts-container">
            <div class="pie-wrapper progress-90">
                <span class="label">90<span     class="smaller">%</span></span>
                 <div class="pie">
                  <div class="left-side half-circle"></div>
                    <div class="right-side half-circle"></div>
            </div>
         </div>
         </div>

         <h3  style={{
             color:'white',
             fontSize:'20px'
             }}>MongoDB</h3>

                </Grid>





                
                <Grid item sx={12} md={4} lg={3}>


                <div class="set-size charts-container">
            <div class="pie-wrapper progress-90">
                <span class="label">90<span     class="smaller">%</span></span>
                 <div class="pie">
                  <div class="left-side half-circle"></div>
                    <div class="right-side half-circle"></div>
            </div>
         </div>
         </div>

         <h3  style={{
             color:'white',
             fontSize:'20px'
             }}>Flutter</h3>

                </Grid>


            

            
                <Grid item sx={12} md={4} lg={3}>


                <div class="set-size charts-container">
            <div class="pie-wrapper progress-90">
                <span class="label">90<span     class="smaller">%</span></span>
                 <div class="pie">
                  <div class="left-side half-circle"></div>
                    <div class="right-side half-circle"></div>
            </div>
         </div>
         </div>

         <h3  style={{
             color:'white',
             fontSize:'20px'
             }}>Node Js</h3>

                </Grid>




            

            
                <Grid item sx={12} md={4} lg={3}>


                <div class="set-size charts-container">
            <div class="pie-wrapper progress-90">
                <span class="label">80<span     class="smaller">%</span></span>
                 <div class="pie">
                  <div class="left-side half-circle"></div>
                    <div class="right-side half-circle"></div>
            </div>
         </div>
         </div>

         <h3  style={{
             color:'white',
             fontSize:'20px'
             }}>MySQL</h3>

                </Grid>










            </Grid>
















            
        </div>
    );
};

export default Skill;