import logo from './logo.svg';
import './App.css';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
// import './App.css';
import Contact from './Components/Contact/Contact';
import Footer from './Components/Footer/Footer';
// import Header from './Components/Header/Header';
import Home from './Components/Home/Home';
import ResponsiveAppBar from "./Components/Header/Header";

function App() {
  return (
    <div className="App">


<Router>
{/* <Header></Header> */}

<ResponsiveAppBar></ResponsiveAppBar>

<Routes>

<Route  path="/home" element={<Home/>} />
<Route  path="/" element={<Home/>} />
<Route  path="/contact" element={<Contact/>} />

</Routes>


<Footer></Footer>

</Router>
    
    </div>
  );
}

export default App;













